import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from './Button';
import SocialMediaLinks from './SocialMediaLinks';
const NavBar = () => {
	const [open, setOpen] = useState(false);

	return (
		<nav className='bg-white border-b-2 shadow-md'>
			<div className='flex items-center font-medium justify-around'>
				<div className='h-15 z-50 p-5 md:w-auto w-full flex justify-between md:' to={'/'}>
					<NavLink to={'/'}>RajerThat</NavLink>
					<div className='text-3xl md:hidden' onClick={() => setOpen(!open)}>
						<ion-icon name={`${open ? 'close' : 'menu'}`}></ion-icon>
					</div>
				</div>

				<ul className='md:flex hidden uppercase items-center gap-8 font-Poppins'>
					<li>
						<NavLink to={'/'} className='py-7 px-3 inline-block'>
							Home
						</NavLink>
					</li>
					<li>
						<NavLink to={'/About'} className='py-7 px-3 inline-block'>
							About
						</NavLink>
					</li>
					<li>
						<NavLink to={'/Blog'} className='py-7 px-3 inline-block'>
							Blog
						</NavLink>
					</li>

					<li>
						<NavLink to={'/Contact'} className='py-7 px-3 inline-block'>
							Contact
						</NavLink>
					</li>
					<SocialMediaLinks />
				</ul>

				<div className='md:block hidden'>
					<Button />
				</div>

				{/* Mobile Nav */}
				<ul
					className={`
					md:hidden
					bg-white
					absolute
					w-full
					h-full
					bottom-0
					py-24
					pl-4
					duration-500 ${open ? 'left-0' : 'left-[-100%]'}
				`}>
					<li>
						<NavLink to={'/'} className='py-7 px-3 inline-block'>
							Home
						</NavLink>
					</li>

					<div className='py-5'>
						<Button />
					</div>
				</ul>
			</div>
		</nav>
	);
};

export default NavBar;
