import React from 'react';
import { Link } from 'react-router-dom';

const Post = (props) => {
	return (
		<div className='flex flex-wrap '>
			{props.postData.map((post) => (
				<ul className='p-2'>
					<Link key={post.id} to={post.id}>
						<li className='max-w-sm h-full overflow-hidden shadow-lg rounded-md' key={post.id}>
							<img className='rounded-md w-full' src={post.imgSrc} />
							<div className='px-6 py-4'>
								<h1 className='px-1 font-sans text-black pb-1 text-3xl'>{post.title}</h1>
								<p className='text-black px-1'>{post.description}</p>
							</div>

							<div className='px-6 pt-4 pb-2'></div>
						</li>
					</Link>
				</ul>
			))}
		</div>
	);
};

export default Post;
