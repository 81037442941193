import React from 'react';
import Post from '../components/Post';

const postData = [
	{
		id: 1,
		title: 'First Post',
		imgSrc: `../../assets/articleImages/article1Image.jpg`,
		description: 'The description of the post goes here. It provides a lasdf asdfasdfasdf',
	},
	{
		id: 2,
		title: 'Second Post',
		imgSrc: `../../assets/articleImages/article1Image.jpg`,
		description: 'The description of the post goes here. It provides a lasdf asdfasdfasdf',
	},
	{
		id: 3,
		title: 'Third Post',
		imgSrc: `../../assets/articleImages/article1Image.jpg`,
		description: 'The description of the post goes here. It provides a lasdf asdfasdfasdf',
	},
];

const Blog = () => {
	return (
		<div className='container mx-auto p-10 border-2 border-gray-500 rounded-md mt-10 flex flex-wrap'>
			<Post postData={postData} />
		</div>
	);
};

export default Blog;
