import React from "react";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  let navigate = useNavigate();

  return (
    <div>
      <h1>This is the Contact page</h1>
      <button
        className='rounded-md border-2 border-rose-600'
        onClick={() => {
          navigate("/About");
        }}>
        Go to About
      </button>
    </div>
  );
};

export default Contact;
