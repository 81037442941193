import React from "react";

const ErrorPage = () => {
  return (
    <div>
      <h1>Error! You seemed to be lost! Page not found!</h1>
    </div>
  );
};

export default ErrorPage;
