import React from 'react';
import { SocialIcon } from 'react-social-icons';

const SocialMediaLinks = () => {
	return (
		<div className='flex flex-wrap m-3'>
			<div className='px-1'>
				<SocialIcon url='https://twitter.com/Raj_Kakar' target='_blank' style={{ height: 50, width: 50 }} />
			</div>
			<div className='px-1'>
				<SocialIcon url='https://linkedin.com/in/rajkakar' target='_blank' style={{ height: 50, width: 50 }} />
			</div>
			<div className='px-1'>
				<SocialIcon url='https://github.com/rajdeep24' target='_blank' style={{ height: 50, width: 50 }} />
			</div>
		</div>
	);
};

export default SocialMediaLinks;
