import React from 'react';

const Button = () => {
	return (
		<button className='bg-slate-500 text-white  px-6 py-2 rounded-md border-4 border-green-400 hover:text-black hover:bg-green-100 transition-colors duration-300 delay-200'>
			Sign-Up
		</button>
	);
};

export default Button;
