import React, { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
} from 'react-router-dom';
import Nav from './components/NavBar';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import ErrorPage from './pages/ErrorPage';

const App = () => {
	return (
		<div>
			<Router>
				<Nav />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/About' element={<About />} />
					<Route path='/Contact' element={<Contact />} />
					<Route path='/Blog' element={<Blog />} />
					<Route path='*' element={<ErrorPage />} />
				</Routes>
			</Router>
		</div>
	);
};

export default App;
