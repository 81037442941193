import React from 'react';
import { Link } from 'react-router-dom';
import ReactGa from 'react-ga';

const Home = () => {
	const handleClick = () => {
		ReactGa.event({
			category: 'Button',
			action: 'Clicked button on home page',
		});
		alert(
			'Send the information to google analytics that the signup button the home page was clicked.'
		);
	};

	return (
		<div className='h-screen bg-Hero bg-cover md:bg-top bg-center'>
			<div className='flex flex-col justify-center text-center items-center h-3/4'>
				<h2 className='text-gray-600 text-2xl font-medium '>
					RajerThat
				</h2>

				<h1 className='md:text-5xl text-3xl text-gray-600 font-semibold py-5'>
					Sign up for my news letter
				</h1>
				<h1 className='md:text-5xl text-3xl text-gray-600 font-semibold py-5'>
					Learn about my journey into full-stack
					development, Aws, and business.
				</h1>
				<button
					className='bg-blue-500 p-3 rounded-md text-white drop-shadow-lg hover:border hover:border-2 hover:border-black hover:bg-blue-400 '
					onClick={handleClick}>
					Sign Up
				</button>
			</div>
		</div>
	);
};

export default Home;
